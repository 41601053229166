/*import React from "react";
import "./Timeline.css";
import assets from "../../assets/assets";

//this component includes timeline of home page

(function () {
  "use strict";

  // define variables
  var items = document.querySelectorAll(".timeline-content li");

  // check if an element is in viewport
  // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
  function isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function callbackFunc() {
    for (var i = 0; i < items.length; i++) {
      if (isElementInViewport(items[i])) {
        items[i].classList.add("in-view");
      }
    }
  }

  // listen for events
  window.addEventListener("load", callbackFunc);
  window.addEventListener("resize", callbackFunc);
  window.addEventListener("scroll", callbackFunc);
})();

function Timeline() {
  return (
    <>
      <div className="Timeline">
        <div className="timeline-title">
          Timeline
          <p id="timeLine">Flow of the event</p>
        </div>
        {/*
        <div className="timeline_content">
          <div className="airplane hiddenOne">
            <img srcSet={assets.TimelinePlane} alt="acmbmu" />
            <div className="dotted-line"></div>
          </div>
          <div className="content_left">
            <div className="box">Lorem ipsum dolor sit amet consectetur</div>
            <div className="box">Lorem ipsum dolor sit amet consectetur</div>
            <div className="box hiddenOne">
              Lorem ipsum dolor sit amet consectetur
            </div>
            <div className="box hiddenOne">
              Lorem ipsum dolor sit amet consectetur
            </div>
          </div>
          <div className="airplane hide">
            <img srcSet={assets.TimelinePlane} alt="acmbmu" />
            <div className="dotted-line"></div>
          </div>
          <div className="content_right">
            <div className="box">Lorem ipsum dolor sit amet consectetur</div>
            <div className="box">Lorem ipsum dolor sit amet consectetur</div>
          </div>
  
  </div>
  <div className="timeline-content">
  <ul>
    <li>
      <div className="box"><time></time>
      Lorem ipsum dolor sit amet consectetur
      </div>
    </li>
    <li>
      <div className="box"><time></time>
      Lorem ipsum dolor sit amet consectetur
      </div>
    </li>
    <li>
      <div className="box"><time></time>
      Lorem ipsum dolor sit amet consectetur
      </div>
    </li>
    <li>
      <div className="box"><time></time>
      Lorem ipsum dolor sit amet consectetur
      </div>
    </li>
    
    
  </ul>
</div>
      </div>
    </>
  );
  
}

export default Timeline;
*/
import React, { useEffect } from "react";
import "./Timeline.css";

const TimelineComponent = () => {
  useEffect(() => {
    const items = document.querySelectorAll(".timeline li");

    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      );
    }

    function callbackFunc() {
      for (var i = 0; i < items.length; i++) {
        const box = items[i].querySelector(".box");
        if (isElementInViewport(box)) {
          items[i].classList.add("in-view");
        } else {
          items[i].classList.remove("in-view");
        }
      }
    }

    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);

    return () => {
      window.removeEventListener("load", callbackFunc);
      window.removeEventListener("resize", callbackFunc);
      window.removeEventListener("scroll", callbackFunc);
    };
  }, []);

  return (
    <>
      <div className="Timeline">
        <div className="timeline-title">
          Timeline
          <p id="timeLine">Flow&nbsp;of&nbsp;the&nbsp;event</p>
        </div>

        <section className="timeline">
          <ul>
            <li>
              <div className="box">
                <time>0</time>{" "}
                <div className="head"> Inaugral&nbsp;Ceremony</div>
                <div className="content">12:00 PM - 12:15 PM</div>
                <div className="head">Hacking&nbsp;Phase&nbsp;I</div>
                <div className="content">12:15 PM - 02:00 PM</div>
              </div>
            </li>
            <li>
              <div className="box">
                <time>1</time>{" "}
                <div className="head">Mentoring&nbsp;Session</div>
                <div className="content">03:00 PM - 05:00 PM</div>
                <div className="head">Hacking&nbsp;Phase&nbsp;II</div>
                <div className="content">06:00 PM - 08:00 PM</div>
              </div>
            </li>
            <li>
              <div className="box">
                <time>2</time>
                <div className="head">Hacking&nbsp;Phase&nbsp;III</div>
                <div className="content">02:00 AM - 08:00 AM</div>
                <div className="head">PPT&nbsp;Preparation</div>
                <div className="content">09:00 AM - 1:00 AM</div>
              </div>
            </li>
            <li>
              <div className="box">
                <time>3</time>{" "}
                <div className="head">
                  Judging&nbsp;&&nbsp;Result Declaration
                </div>
                <div className="content">11:00 AM - 04:30 PM</div>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default TimelineComponent;
