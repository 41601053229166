import React from "react";
import "./About.css";
import assets from "../../assets/assets";

function About() {
  return (
    <>
      <div className="Sponser">
        <div class="logos">
          <div class="logos-slide">
            <img srcSet={assets.spheron} alt="" />
            <img srcSet={assets.balsamiq} alt="" />
            {/* <img srcSet={assets.1pass} alt="" /> */}
            <img srcSet={assets.mlh} alt="" />
            <img srcSet={assets.axure} alt="" />
            <img srcSet={assets.leadl} alt="" />
            <img srcSet={assets.taskade} alt="" />
            <img srcSet={assets.echoAR} alt="" />
            <img srcSet={assets.wolf} alt="" />
            <img srcSet={assets.devfolio} alt="" />
            <img srcSet={assets.CN} alt="" />
            <img srcSet={assets.test} alt="" />
            <img srcSet={assets.matic} alt="" />
            <img srcSet={assets.cyb} alt="" />
            <img srcSet={assets.slido} alt="" />
            <img srcSet={assets.cb} alt="" />
          </div>
          <div class="logos-slide">
          <img srcSet={assets.spheron} alt="" />
            <img srcSet={assets.balsamiq} alt="" />
            {/* <img srcSet={assets.1pass} alt="" /> */}
            <img srcSet={assets.mlh} alt="" />
            <img srcSet={assets.axure} alt="" />
            <img srcSet={assets.leadl} alt="" />
            <img srcSet={assets.taskade} alt="" />
            <img srcSet={assets.echoAR} alt="" />
            <img srcSet={assets.wolf} alt="" />
            <img srcSet={assets.devfolio} alt="" />
            <img srcSet={assets.CN} alt="" />
            <img srcSet={assets.test} alt="" />
            <img srcSet={assets.matic} alt="" />
            <img srcSet={assets.cyb} alt="" />
            <img srcSet={assets.slido} alt="" />
            <img srcSet={assets.cb} alt="" />
          </div>
        </div>
      </div>
      <div className="About">
        <div className="about-us">
          <p className="heading" id="aboutus">
            About&nbsp;Us
          </p>
          <p id="one">
            The largest student-driven club at BMU, ACM presents its flagship
            event, HackBMU 6.0 now back to its roots as a completely offline 24
            hour hackathon. Time to pull up those sleeves and collaborate with
            your team in this electrifying marathon of creativity and
            innovation. Race against time as your problem solving capabilities
            get limit-tested in this thrilling journey of rapid ideation and
            prototyping where the sky's the limit for experimentation. Join us
            and be part of something truly remarkable – because the future is
            waiting to be hacked!
          </p>
        </div>
        <div className="most-hyped-hackathon">
          <p className="heading gradient-text">
            Most&nbsp;Hyped&nbsp;Hackathon
          </p>
          <p id="one">
            Our hugely successful hybrid predecessor, HackBMU 5.0 attracted 633
            participants from 13+ countries, garnering a staggering sponsorship
            of over 70 Lakhs as well as prizes worth over 85 lakhs. As we go
            back to our fully offline roots with the 6th edition, we are poised
            to create an unparalleled experience for participants and sponsors
            alike in this event that has become synonymous with innovation,
            creativity, and networking.
          </p>
          <div className="stats">
            <div className="stats-items">
              <p className="stats-num gradient-text">
                <b>600+</b>
              </p>
              <p className="stats-info gradient-text">Registrations</p>
            </div>
            <div className="stats-items">
              <p className="stats-num gradient-text">
                <b>50+</b>
              </p>
              <p className="stats-info gradient-text">Teams</p>
            </div>
            <div className="stats-items">
              <p className="stats-num gradient-text">
                <b>50k+</b>
              </p>
              <p className="stats-info gradient-text">Worth Prizes</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
