import React, { useEffect } from "react";
import Home from "../components/Home/Home";
import About from "../components/About/About";
import Faq from "../components/Faq/Faq";
import Timeline from "../components/Timeline/Timeline";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
import Lenis from "@studio-freight/lenis";
import AOS from "aos";
import "aos/dist/aos.css";

function HomePage() {
  useEffect(() => {
    AOS.init();
  }, []);
  const lenis = new Lenis({
    duration: 2,
  });

  lenis.on("scroll", (e) => {
    console.log(e);
  });
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  return (
    <>
      <Home />
      <About />
      <Timeline />
      <Contact />
      <Faq />
      <Footer />
    </>
  );
}

export default HomePage;
