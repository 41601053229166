import React from "react";
import "./Contact.css";
import assets from "../../assets/assets";

function Contact() {
  return (
    <>
      <section className="contact">
        <p className="title gradient-text" id="contact">
          Get&nbsp;In&nbsp;Touch
        </p>
        <div className="pocs" id="poc-container">
          <div className="poc poc1">
            <a
              href="https://acmdemo.netlify.app/"
              target="_blank"
              className="details"
            >
              <img src={assets.acmlogo} className="avatar" alt="" />
              <div className="detail">
                <p className="name gradient-text">acm.bmu</p>
              </div>
            </a>
            <a href="tel:+91-8826427240" className="link">
              <i className="fa-solid fa-phone-volume"></i>
              <p>+91-8826427240</p>
            </a>
            <a href="mailto:acm@bmu.edu.in" className="link">
              <i className="fa-solid fa-envelope"></i>
              <p>acm@bmu.edu.in</p>
            </a>
            <a
              href="https://maps.app.goo.gl/AYBNN3yeK3xrMf8e6?g_st=iw"
              className="link"
            >
              <i className="fa-solid fa-location-dot"></i>
              <p>
                BML&nbsp;Munjal&nbsp;University, Gurgaon,
                <br />
                Haryana, India (122413)
              </p>
            </a>
          </div>
          <div className="partition"></div>
          <div className="poc poc2">
            <a
              id="a1"
              href="https://www.instagram.com/hackbmu?igsh=MW91MnZkaG95OHF1Mw=="
              className="link"
            >
              <i className="fa-brands fa-square-instagram"></i>
              <p>hackbmu</p>
            </a>
            <a
              id="a2"
              href="https://www.instagram.com/acm_bmu?igsh=NTF2N3MxOWs2OHhu"
              className="link"
            >
              <i className="fa-brands fa-square-instagram"></i>
              <p>acm.bmu</p>
            </a>
            <a
              id="a3"
              href="https://www.linkedin.com/company/bmu-acm-student-chapter/"
              className="link"
            >
              <i className="fa-brands fa-linkedin"></i>
              <p>HackBMU</p>
            </a>

            <a
              id="a4"
              href="https://www.linkedin.com/company/bmu-acm-student-chapter/"
              className="link"
            >
              <i className="fa-brands fa-linkedin"></i>
              <p>ACM BMU Student Chapter</p>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
