import acmlogo from "../assets/images/acmlogo.png";
import logo from "../assets/images/hackbmu.png";
import devfolio from "../assets/images/Devfolio.png";
import codeasy from "../assets/images/codeasy.png";
import TimelinePlane from "../assets/images/TimelinePlane.png";
import pic1 from "../assets/images/pic1.png";
import pic2 from "../assets/images/pic2.png";
import pic3 from "../assets/images/pic3.png";
import CN from "../assets/images/CN.png";
import cb from "../assets/images/cb.png";
import draftbit from "../assets/images/draftbit.png";
import echoAR from "../assets/images/echoAR.png";
import axure from "../assets/images/axure.png";
import badelog from "../assets/images/badelog.png";
import balsamiq from "../assets/images/balsamiq.png";
import board from "../assets/images/board-infinity.png";
import codechef from "../assets/images/codechef.png";
import crypto from "../assets/images/cryptopolitan.png";
import DO from "../assets/images/DO.png";
import ed from "../assets/images/ed.png";
import egg from "../assets/images/egghead.png";
import framer from "../assets/images/framer.png";
import gmc from "../assets/images/GMC.png";
import inm from "../assets/images/inm.png";
import interview from "../assets/images/interviewcake.png";
import jetbrains from "../assets/images/jetbrains.png";
import matic from "../assets/images/Matic.png";
import portis from "../assets/images/Portis.png";
import rm from "../assets/images/RM.png";
import sashido from "../assets/images/sashido.png";
import sketch from "../assets/images/sketch.png";
import thecodex from "../assets/images/theCodex.png";
import hackbmulogo from "../assets/images/hackbmulogo.png";
import gfg from "../assets/images/371ba38b-8a03-4bff-916c-c3fa5396ceda.jpeg";
import gatsby from "../assets/images/Gatsby_logo.png";
import pivony from "../assets/images/pivony-logo.png";
import hiephen from "../assets/images/maxresdefault.jpg";
import Duass from "../assets/images/L2Cl9yAk_400x400.jpg";
import bugsy from "../assets/images/17132399.png";
import devcirc from "../assets/images/download (1).png";
import yourst from "../assets/images/5ae98eec19474e137b0cd147_YS-logos-01.png";
import kagd from "../assets/images/kdmdelhi_logo.jpeg";
import znet from "../assets/images/znetlivelogo-white1.jpg";
import icons from "../assets/images/download (2).png";
import sentry from "../assets/images/sentry8508.jpg";
import dub from "../assets/images/header-logo-du-beat-updated-2.png";
import xyz from "../assets/images/1280px-.xyz_logo.svg.png";
import rap from "../assets/images/download (3).png";
import no from "../assets/images/NSP.png";
import oh from "../assets/images/download (4).png";
import echo from "../assets/images/echoAR.png";
import spheron from "../assets/images/63bcb3f65f3737af66d79f0c_d8uHlrvmK5wBJYSsFJIpoZRpeD4AGcCXy-jeSSeEIDw.png";
import cyb from "../assets/images/logo_1278_052e61f07e85da4a595c51a83c8cf6ed.png";
import codnin from "../assets/images/CN.png";
import slido from "../assets/images/download.png";
import test from "../assets/images/logo-testmail-b5895796ebb41b794e3d8d0fcd50db88d9f874446f135dd8febafe69c16501fa.jpg";
import leadl from "../assets/images/Leading+Learners+Full+Text+Logo-f022afed-640w.webp";
import wolf from "../assets/images/1280px-WolframCorporateLogo.svg.png";
import mlh from "../assets/images/Mlh-logo-color.svg.png";
import acmOne from "../assets/images/acmOne.png";
import bmuOne from "../assets/images/bmuOne.png";
import onepass from "../assets/images/1pass.png";
import taskade from "../assets/images/taskade-circle-logo-full-black.png";
import cloud from "../assets/images/cloudsploit-logo.png";
import doist from "../assets/images/CS-Doist-logo.png";
import dev from "../assets/images/ym0352iqgzyle1nmqhzk.png";
import tim from "../assets/images/black_center.webp";

const assets = {
  acmOne,
  bmuOne,
  leadl,
  mlh,
  wolf,
  echo,
  spheron,
  cyb,
  codnin,
  slido,
  test,
  oh,
  no,
  rap,
  xyz,
  dub,
  sentry,
  icons,
  znet,
  kagd,
  yourst,
  devcirc,
  bugsy,
  Duass,
  hiephen,
  pivony,
  gatsby,
  gfg,
  acmlogo,
  logo,
  devfolio,
  codeasy,
  TimelinePlane,
  pic1,
  pic2,
  pic3,
  CN,
  cb,
  draftbit,
  echoAR,
  thecodex,
  sketch,
  sashido,
  rm,
  portis,
  matic,
  jetbrains,
  interview,
  inm,
  gmc,
  framer,
  egg,
  ed,
  DO,
  crypto,
  codechef,
  board,
  balsamiq,
  badelog,
  axure,
  hackbmulogo,
  onepass,
  taskade,
  cloud,
  doist,
  dev,
  tim,
};

export default assets;
