import React from "react";
import "./Footer.css";
import assets from "../../assets/assets";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer>
        <div className="content">
          <div className="program">
            <p className="sub-title">Get&nbsp;Latest&nbsp;Updates!</p>
            <p className="desc">
              Discover the benefits of partnering with us and reach your target
              audience.
            </p>
            <form className="subscribe">
              <input type="email" className="email" placeholder="Email Id" />
              <button className="primary">Subscribe&nbsp;Now</button>
            </form>
          </div>
          <div className="items">
            <div className="line">
              <img src={assets.logo} alt="hackbmu" />
              <div className="links">
                <NavLink exact to="/" activeClassName="active">
                  Home
                </NavLink>
                <a href="#aboutus">About&nbsp;Us</a>
                <NavLink exact to="/sponsor" activeClassName="active">
                  Sponsors
                </NavLink>
                <a href="#faqs">FAQ</a>
              </div>
            </div>

            <hr />
            <div className="end line">
              <div className="logoOne">
                <img srcSet={assets.bmuOne} alt="BMU" id="logoFooter" />
                <img srcSet={assets.acmOne} alt="ACM" id="logoFooter" />
              </div>
              <p className="cr">
                &copy;&nbsp;2024&nbsp;All&nbsp;Rights&nbsp;reserved&nbsp;with&nbsp;ACM&nbsp;BMU
              </p>
              <div className="links">
                <a href="https://www.instagram.com/hackbmu?igsh=MW91MnZkaG95OHF1Mw==">
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/bmu-acm-student-chapter/">
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a href="mailto:acm@bmu.edu.in">
                  <i className="fa-solid fa-envelope"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
