import React from "react";
import "./Home.css";
import Navbar from "../Navbar/Navbar";

function Home() {
  return (
    <>
      <div className="hero-bg" data-aos="fade-right">
        <div className="overlay"></div>
        <Navbar />
        <div className="Home" id="homee">
          <p className="title">
            Code,&nbsp;Create,&nbsp;Conquer <br />{" "}
            Unleash&nbsp;your&nbsp;digital&nbsp;prowess!
          </p>

          <a
            href="https://unstop.com/hackathons/hackbmu-60-bml-munjal-university-bmu-gurgaon-947305"
            target="_blank"
            className="button"
          >
            Register&nbsp;Now
          </a>
          <p id="presented">Presented&nbsp;by&nbsp;ACM&nbsp;BMU</p>
        </div>
      </div>
    </>
  );
}

export default Home;
