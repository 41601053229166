import React from "react";
import "./PastPartner.css";
import assets from "../../assets/assets";

const PartnerLogos = [
  { image: assets.gfg, alt: "gfg" }, // partner
  { image: assets.hiephen, alt: "hie" }, // partner
  { image: assets.dub, alt: "dub" }, // partner
  { image: assets.Duass, alt: "Dua" }, // partner
  { image: assets.codechef, alt: "CodeChef" }, // partners
  { image: assets.pivony, alt: "piv" }, // partner
  { image: assets.bugsy, alt: "bug" }, // partner
  { image: assets.ed, alt: "ED" }, // partner
  { image: assets.gatsby, alt: "gat" }, // partner
  { image: assets.devcirc, alt: "devcirc" }, // partner
  { image: assets.xyz, alt: "xyz" }, // partner
  { image: assets.cloud, alt: "cloud" }, // partner
  { image: assets.yourst, alt: "your" }, // partner
  { image: assets.kagd, alt: "kag" }, // partner
  { image: assets.dev, alt: "dev" }, // partner
  { image: assets.tim, alt: "tim" }, // partner
  { image: assets.doist, alt: "doist" }, // partner
  { image: assets.znet, alt: "znet" }, // partner
  { image: assets.sentry, alt: "sentry" }, // partner
  { image: assets.rap, alt: "rap" }, // partner
  { image: assets.inm, alt: "INM" }, // partner
  { image: assets.rm, alt: "rosenfeld" }, // partner
  { image: assets.no, alt: "no" }, // partner
  { image: assets.oh, alt: "oh" }, // partner
  { image: assets.icons, alt: "icons" }, // partner
];

function PastPartner() {
  return (
    <>
      <section className="past-sponsors">
        <div className="past">
          <div className="past-title">Past&nbsp;Partners</div>
          <div className="past-desc">
            Our hugely successful hybrid predecessor, HackBMU 5.0 attracted 633
            participants from 13+ countries, garnering a staggering sponsorship
            of over 70 Lakhs as well as prizes worth over 85 lakhs
          </div>
        </div>
        <div className="pSponsors">
          {PartnerLogos.map((partner, index) => (
            <div className="p" key={index}>
              <img src={partner.image} alt={partner.alt} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default PastPartner;
