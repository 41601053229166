import "./App.css";
import HomePage from "./pages/HomePage";
import SponsorPage from "./pages/SponserPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<HomePage />} path="/" />
          <Route element={<SponsorPage />} path="/sponsor" />
        </Routes>
      </Router>
    </>
  );
}

export default App;
