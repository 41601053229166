import React, { useState } from "react";
import "./Faq.css";

function Faq() {
  const [openFaq, setOpenFaq] = useState(null); // To track the currently open FAQ

  const toggleContent = (faqNumber) => {
    setOpenFaq(faqNumber === openFaq ? null : faqNumber); // Toggle on or off
  };

  return (
    <>
      <div className="Faq">
        <div className="faq-title" id="faqs">
          FAQs
        </div>
        <div className="faq-content">
          <div className="how_to_register" onClick={() => toggleContent(1)}>
            {/* Question title */}
            How do I register?
            {/* Arrow icon with rotation based on open state */}
            <i
              className={`fa fa-angle-down ${openFaq === 1 ? "rotate" : ""}`}
            ></i>
            {/* Hidden content with conditional rendering */}
            <p
              className={`hidden-content fadeAnimation ${
                openFaq === 1 ? "show" : ""
              }`}
            >
              – To register for HackBMU 6.0, visit the Unstop. There, you'll
              find the complete registration process. Fill in all the required
              details accurately to finalize your registration.
            </p>
          </div>
          {/* Similar structure for other FAQs (2, 3, 4) */}
          <div className="how_to_register" onClick={() => toggleContent(2)}>
            Who is eligible to participate?
            <i
              className={`fa fa-angle-down ${openFaq === 2 ? "rotate" : ""}`}
            ></i>
            <p
              className={`hidden-content fadeAnimation ${
                openFaq === 2 ? "show" : ""
              }`}
            >
              – If you're an undergraduate student excited about innovation and
              collaboration, this hackathon is open to you, regardless of your
              field of study.
            </p>
          </div>
          <div className="how_to_register" onClick={() => toggleContent(3)}>
            Do I need a team to participate?
            <i
              className={`fa fa-angle-down ${openFaq === 3 ? "rotate" : ""}`}
            ></i>
            <p
              className={`hidden-content fadeAnimation ${
                openFaq === 3 ? "show" : ""
              }`}
            >
              – Certainly not! You don't necessarily need a team to participate.
              If you can't find a suitable group, don't worry, we're here for
              you. You can register as an individual, and we'll make sure to
              connect you with like-minded participants who share your interests
              and help form your team.
            </p>
          </div>
          <div className="how_to_register" onClick={() => toggleContent(4)}>
            Do I need coding experience?
            <i
              className={`fa fa-angle-down ${openFaq === 4 ? "rotate" : ""}`}
            ></i>
            <p
              className={`hidden-content fadeAnimation ${
                openFaq === 4 ? "show" : ""
              }`}
            >
              – No coding experience? Absolutely no problem! Whether you're a
              seasoned coder or new to programming, all are welcome at our
              hackathon. Remember, everyone experiences their first hackathon.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faq;
