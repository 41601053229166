import React from "react";
import "./Past.css";
import assets from "../../assets/assets";

const sponsorLogos = [
  { image: assets.spheron, alt: "spheron" }, // sponsor
  { image: assets.balsamiq, alt: "balsamiq" }, // sponsor
  { image: assets.onepass, alt: "onepass" }, // sponsor
  { image: assets.mlh, alt: "mlh" }, // sponsor
  { image: assets.axure, alt: "axure" }, // sponsor
  { image: assets.leadl, alt: "leadl" }, // sponsor
  { image: assets.taskade, alt: "taskade" }, // sponsor
  { image: assets.echoAR, alt: "echoar" }, // sponsor
  { image: assets.wolf, alt: "wolf" }, // sponsor
  { image: assets.devfolio, alt: "devfolio" }, // sponsor
  { image: assets.codnin, alt: "codnin" }, // sponsor
  { image: assets.test, alt: "test" }, // sponsor
  { image: assets.matic, alt: "matic" }, // sponsor
  { image: assets.cyb, alt: "cyb" }, // sponsor
  { image: assets.slido, alt: "slido" }, // sponsor
  { image: assets.cb, alt: "cb" }, // sponsor
];

function Past() {
  return (
    <>
      <section className="past-sponsorss">
        <div className="past">
          <div className="past-title">Past&nbsp;Sponsors</div>
          <div className="past-desc">
            Our hugely successful hybrid predecessor, HackBMU 5.0 attracted 633
            participants from 13+ countries, garnering a staggering sponsorship
            of over 70 Lakhs as well as prizes worth over 85 lakhs
          </div>
        </div>
        <div className="pSponsors">
          {sponsorLogos.map((sponsor, index) => (
            <div className="p" key={index}>
              <img src={sponsor.image} alt={sponsor.alt} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Past;
