import React from "react";
import "./Sponser.css";
import assets from "../../assets/assets";

function Sponser() {
  return (
    <>
      <section className="sponsors">
        <div className="sponsor-title">
          What&nbsp;Do&nbsp;Sponsor's&nbsp;Get?
        </div>
        <div className="sponsors-sub-title">
          Unlock boundless opportunities as a HackBMU sponsor. <br /> Experience
          innovation firsthand and connect with a diverse community driven by
          passion.
        </div>
        <div className="cards">
          <div className="card">
            <div classname="image">
              <img src={assets.pic1} alt="acmbmu" id="img" />
            </div>
            <div className="text">
              <div className="sponsor-sub-title">
                Innovative Hackathon Experience
              </div>
              <div className="sponsor-sub-desc">
                Join us in creating groundbreaking solutions at our vibrant
                hackathon events. Experience the thrill of collaborative
                problem-solving.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="image">
              <img src={assets.pic2} alt="acmbmu" id="img" />
            </div>
            <div className="text">
              <div className="sponsor-sub-title">
                Customized Sponsorship Opportunities
              </div>
              <div className="sponsor-sub-desc">
                From branding opportunities to exclusive networking sessions, we
                offer flexible options to maximize your impact and ROI.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="image">
              <img src={assets.pic3} alt="acmbmu" id="img" />
            </div>
            <div className="text">
              <div className="sponsor-sub-title">
                Unmatched Exposure and Branding Opportunities
              </div>
              <div className="sponsor-sub-desc">
                From social media shoutouts to logo placement on event
                materials, our sponsorship packages offer unparalleled
                visibility to the target audience.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Sponser;
