import React from "react";
import "./Stats.css";

function Stats() {
  return (
    <>
      <section className="numbers">
        <p className=" gradient-text heading">Behind&nbsp;the&nbsp;Numbers</p>
        <p className="sub-title" id="one">
          These figures highlight the vibrant community of innovators and
          problem-solvers that HackBMU attracts, making it an ideal platform for
          sponsors to connect with the brightest minds and support cutting-edge
          projects.
        </p>
        <br />
        <br />
        <div className="facts">
          <div className="fact">
            <p className="number gradient-text " id="numb">
              600+
            </p>
            <p className="desc gradient-text">Registrations</p>
          </div>
          <div className="fact">
            <p className="number gradient-text " id="numb">
              50+
            </p>
            <p className="desc gradient-text">Teams</p>
          </div>
          <div className="fact">
            <p className="number gradient-text " id="numb">
              50k+
            </p>
            <p className="desc gradient-text"> Worth Prizes</p>
          </div>
        </div>
      </section>
      <section id="pricing">
        <div className="stat-title">
          Plans&nbsp;For&nbsp;Your&nbsp;Sponsorship&nbsp;Journey
        </div>
        <div className="plans">
          <div className="plan">
            <div className="content">
              <div>
                <p className="plan-type">SILVER&nbsp;SPONSOR</p>
                {/* <p className="plan-desc">Basic Plan</p> */}
              </div>
              <div className="pricing">
                <span className="rupee">&#8377;</span>
                <span className="price">17,999</span>
                {/* <span className="duration">/ Month</span> */}
              </div>
              <div className="features">
                <p className="f">Starter Features</p>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Reserved Booth Space.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Marketing access over the event days.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Extensive Social Media coverage.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Logos over Printables.</p>
                </div>
              </div>
            </div>
            <a href="https://forms.gle/WNMKHKc8QTaPHcar7" className="primary">
              Enroll&nbsp;Now
            </a>
          </div>
          <div className="plan">
            <div className="content">
              <div>
                <p className="plan-type">GOLD&nbsp;SPONSOR</p>
                {/* <p className="plan-desc">Basic Plan</p> */}
              </div>
              <div className="pricing">
                <span className="rupee">&#8377;</span>
                <span className="price">24,999</span>
                {/* <span className="duration">/ Month</span> */}
              </div>

              <div className="features">
                <p className="f">Starter Features</p>

                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Reserved Booth Space.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Marketing access over the event days.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Speaker Session.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Promotion before each event.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Extensive Social Media coverage.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Exclusive Social Media Promotion.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Logos over Printables.</p>
                </div>
              </div>
            </div>

            <a href="https://forms.gle/WNMKHKc8QTaPHcar7" className="primary">
              Enroll&nbsp;Now
            </a>
          </div>
          <div className="plan">
            <div className="content">
              <div>
                <p className="plan-type">TITLE&nbsp;SPONSOR</p>
                {/* <p className="plan-desc">Basic Plan</p> */}
              </div>
              <div className="pricing">
                <span className="rupee">&#8377;</span>
                <span className="price">49,999</span>
                {/* <span className="duration">/ Month</span> */}
              </div>

              <div className="features">
                <p className="f">Starter Features</p>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Reserved Booth Space.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Marketing access over the event days.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>
                    Speaker Session, and open interaction with the participants.
                  </p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Promotion before each event.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Extensive Social Media coverage.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Exclusive Social Media Promotion.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Logos on Event Merchandise.</p>
                </div>
                <div className="feature">
                  <div className="check">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <p>Logos over Printables, including Certificates.</p>
                </div>
              </div>
            </div>
            <a href="https://forms.gle/WNMKHKc8QTaPHcar7" className="primary">
              Enroll&nbsp;Now
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Stats;
