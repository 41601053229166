import React from "react";
import Hero from "../components/Hero/Hero";
import Footer from "../components/Footer/Footer";
import Contact from "../components/Contact/Contact";
import Stats from "../components/Stats/Stats";
import Sponser from "../components/Sponser/Sponser";
import Past from "../components/PastSponsor/Past";
import Lenis from "@studio-freight/lenis";
import PastPartner from "../components/PastPartners/PastPartner";

function SponsorPage() {
  const lenis = new Lenis({
    duration: 2,
  });

  lenis.on("scroll", (e) => {
    console.log(e);
  });
  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);
  return (
    <>
      {" "}
      <Hero />
      <Sponser />
      <Stats />
      <Contact />
      <Past />
      <PastPartner/>
      <Footer />
    </>
  );
}

export default SponsorPage;
