import React from "react";
import "./Navbar.css";
import assets from "../../assets/assets";
import { useState } from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <nav className="navbar">
        <div className="nav-1">
          <NavLink exact to="/">
            <img src={assets.logo} alt="hackbmu" />
          </NavLink>
          <a
            href="https://unstop.com/hackathons/hackbmu-60-bml-munjal-university-bmu-gurgaon-947305"
            target="_blank"
          >
            <button className="primary" id="homeRegiterbutton">
              Register
            </button>
          </a>

          <button
            className="ham"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <div className={toggle ? "nav-2 active" : "nav-2"}>
          <NavLink exact to="/" activeClassName="active">
            Home
          </NavLink>
          <a href="#aboutus">About&nbsp;Us</a>
          <NavLink exact to="/sponsor" activeClassName="active">
            Sponsors
          </NavLink>

          <a href="#contact">Contact&nbsp;Us</a>
          <a href="#faqs">FAQ</a>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
