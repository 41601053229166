import React from "react";
import "./Hero.css";
import Navbar from "../Navbar/Navbar";

function Hero() {
  return (
    <>
      <div className="hero-bg">
        <div className="overlayT"></div>
        <Navbar />
        <div className="Home">
          <p className="title">
            Innovate,&nbsp;Collaborate,&nbsp;Impact
            <br /> Unleash&nbsp;your&nbsp;Sponsorship&nbsp;Journey!
          </p>
          <p id="presented">Presented&nbsp;by&nbsp;ACM&nbsp;BMU</p>
        </div>
      </div>
    </>
  );
}

export default Hero;
